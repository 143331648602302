import React from "react";
import CalendarIcon from '../../../static/assets/articleBlog/calendar_month.svg'
import ScheduleIcon from '../../../static/assets/articleBlog/schedule.svg'
import HeaderComponent from '../headerComponent'
import { GatsbyImage } from "gatsby-plugin-image";
const ArticleHomeComponent = (props) =>{
    const {
        articleTitle,
        articleText,
        articleData,
        articleTime,
        ArticleImage
    } = props;
    return (
      <div className="article-home-section">
        <HeaderComponent />
        <div className="full-container">
          <div className="article-home-section-wrapper">
            <GatsbyImage
              alt="articleImage"
              image={ArticleImage}
              className="article-home-section-image"
            />
            <div className="article-home-section-wrapper-text">
              <h1 className="article-home-section-title">{articleTitle}</h1>
              <p
                className="article-home-section-text"
                dangerouslySetInnerHTML={{ __html: articleText }}
              ></p>
              <div className="article-home-section-data">
                <CalendarIcon className="article-home-section-data-icon" />
                <p className="article-home-section-data-text">{articleData}</p>
                <ScheduleIcon className="article-home-section-data-icon" />
                <p className="article-home-section-data-text">{articleTime}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}
export default ArticleHomeComponent