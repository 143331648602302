import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const ArticleComponent = props => {
  const { articleHeadText, articleTextImage, articleEndImage, articleText } =
    props;
  return (
    <div className='article-section'>
      <div className='full-container'>
        <p
          className='article-section-text'
          dangerouslySetInnerHTML={{ __html: articleHeadText }}
        ></p>
        <GatsbyImage
          alt='articleImage'
          image={articleTextImage}
          className='article-section-image'
        />
        <p
          className='article-section-text'
          dangerouslySetInnerHTML={{
            __html: articleText.replace('Motomtech', 'UpTech Sh.P.K'),
          }}
        ></p>
        <GatsbyImage
          alt='endArticleImage'
          image={articleEndImage}
          className='article-section-image'
        />
      </div>
    </div>
  );
};
export default ArticleComponent;
